import axios from "axios";
import cogoToast from "cogo-toast";
import {
  setAuth,
  UnsetAuth,
  registerAuth,
  redirectState,
  existingAuth,
  loadingAuth,
  loadingFalse,
} from "../slices/auth-slice";
import { resetAddress } from "../slices/address-slice";
import { resetOrder } from "../slices/orderReducers";

const API_URL = process.env.REACT_APP_API_HOST;

export const userLogin = (login) => {
  return async (dispatch) => {
    try {
      dispatch(loadingAuth());
      await axios
        .post(`${API_URL}/auth/login`, {
          email: login.email,
          password: login.password,
        })
        .then((res) => {
          dispatch(setAuth(res.data));
          localStorage.setItem("xat", res.data.token);
          cogoToast.success(
            <div>
              <p>Welcome Back {res.data.firstName}</p>
            </div>,
            { position: "top-center" }
          );
        })
        .catch((err) => {
          cogoToast.error(err.response.data.message, {
            position: "top-center",
          });
          dispatch(loadingFalse());
        });
    } catch (error) {
      cogoToast.error("Internal Server Error", {
        position: "top-center",
      });
      dispatch(loadingFalse());
    }
  };
};

export const userLogout = () => {
  return async (dispatch) => {
    try {
      dispatch(UnsetAuth());
      dispatch(redirectState());
      dispatch(resetAddress());
      dispatch(resetOrder());
      localStorage.removeItem("xat");
      cogoToast.success(
        <div>
          <p>Logout Successfull</p>
        </div>,
        { position: "top-center" }
      );
    } catch (error) {
      cogoToast.error("Logout Failed");
    }
  };
};

export const registerUser = (data) => {
  return async (dispatch) => {
    try {
      dispatch(loadingAuth());

      await axios
        .post(`${API_URL}/auth/signup`, {
          data,
        })
        .then((res) => {
          dispatch(registerAuth(res.data));
          cogoToast.success("Registered Successfully!", {
            position: "top-center",
          });
          localStorage.setItem("xat", res.data.token);
        })
        .catch((err) => {
          cogoToast.error(err.response.data.message, {
            position: "top-center",
          });
          dispatch(loadingFalse());
        });
    } catch (error) {
      cogoToast.error("Registration Failed");
    }
  };
};

export const existingCustomer = (data) => {
  return async (dispatch) => {
    dispatch(loadingAuth());

    try {
      await axios
        .post(`${API_URL}/auth/register-existing`, data)
        .then((res) => {
          dispatch(existingAuth(res.data));
        })
        .catch((err) => {
          cogoToast.error("Invalid Credentials");
          dispatch(loadingFalse());
        });
    } catch (error) {
      cogoToast.error("Login Failed");
      dispatch(loadingFalse());
    }
  };
};

export const editProfile = (data) => {
  return async (dispatch) => {
    try {
      dispatch(loadingAuth());
      await axios
        .post(`${API_URL}/auth/edit`, data, {
          headers: {
            "x-auth-token": localStorage.getItem("xat"),
          },
        })
        .then((res) => {
          dispatch(existingAuth(res.data));
          cogoToast.success("Profile Updated Successfully!", {
            position: "top-center",
          });
        })
        .catch((err) => {
          dispatch(loadingFalse());

          cogoToast.error("Invalid Credentials");
        });
    } catch (error) {
      cogoToast.error("Login Failed");
      dispatch(loadingFalse());
    }
  };
};

export const passwordReset = (data) => {
  return async (dispatch) => {
    try {
      dispatch(loadingAuth());
      await axios
        .post(`${API_URL}/auth/change-password`, data, {
          headers: {
            "x-auth-token": localStorage.getItem("xat"),
          },
        })
        .then((res) => {
          cogoToast.success("Password Reset Successfully!", {
            position: "top-center",
          });
          dispatch(loadingFalse());
        })
        .catch((err) => {
          cogoToast.error("Invalid Credentials");
          dispatch(loadingFalse());
        });
    } catch (error) {
      cogoToast.error("Login Failed");
      dispatch(loadingFalse());
    }
  };
};
