import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getDiscountPrice, price, taxAmount } from "../../../helpers/product";
import { deleteFromCart, updateCart } from "../../../store/slices/cart-slice";
import { useEffect } from "react";

const MenuCart = () => {
  let cartTotalPrice = 0;
  let couponDiscountPrice = 0;
  let totalDiscount = 0;
  let subTotal = 0;
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.currency);
  const { cartItems } = useSelector((state) => state.cart);
  const { productsInCoupon, discount } = useSelector((state) => state.coupon);

  useEffect(() => {
    if (productsInCoupon && productsInCoupon?.length > 0 && discount > 0) {
      // eslint-disable-next-line array-callback-return
      cartItems.map((item) => {
        if (productsInCoupon.includes(item._id)) {
          dispatch(updateCart({ ...item, couponsDiscount: discount }));
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsInCoupon, discount]);

  return (
    <div className="shopping-cart-content">
      {cartItems && cartItems.length > 0 ? (
        <Fragment>
          <ul>
            {cartItems.map((item) => {
              const discountedPrice = getDiscountPrice(
                item.price,
                item.discount
              );

              const couponDiscount = getDiscountPrice(
                item.price,
                item?.couponsDiscount
              );

              const finalProductPrice = (
                item.price * currency.currencyRate
              ).toFixed(2);

              subTotal += +finalProductPrice * item.quantity;
              cartTotalPrice += +finalProductPrice * item.quantity;

              const finalDiscountedPrice = (
                discountedPrice * currency.currencyRate
              ).toFixed(2);

              const couponDiscountedPrice = (
                couponDiscount * currency.currencyRate
              ).toFixed(2);

              if (discountedPrice !== null) {
                totalDiscount += finalProductPrice - finalDiscountedPrice;
                totalDiscount = item.quantity * totalDiscount;
                cartTotalPrice -= finalProductPrice - discountedPrice;
              }

              if (couponDiscount !== null) {
                couponDiscountPrice +=
                  (finalProductPrice - couponDiscountedPrice) * item.quantity;

                cartTotalPrice -=
                  (finalProductPrice - couponDiscountedPrice) * item.quantity;
              }

              return (
                <li className="single-shopping-cart" key={item.cartItemId}>
                  <div className="shopping-cart-img">
                    <Link to={process.env.PUBLIC_URL + "/product/" + item.slug}>
                      <img
                        alt=""
                        src={item?.images[0] || "/assets/img/placeholder.png"}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link
                        to={process.env.PUBLIC_URL + "/product/" + item.slug}
                      >
                        {" "}
                        {item.name}{" "}
                      </Link>
                    </h4>
                    {item.ringSize && item.ringSize !== "" && (
                      <span className="py-2">Size: {item.ringSize}</span>
                    )}
                    <h6>Qty: {item.quantity}</h6>
                    <span>${price(+finalProductPrice * +item.quantity)}</span>
                    <br />

                    {item.selectedProductColor && item.selectedProductSize ? (
                      <div className="cart-item-variation">
                        <span>Color: {item.selectedProductColor}</span>
                        <span>Size: {item.selectedProductSize}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="shopping-cart-delete">
                    <button
                      onClick={() => dispatch(deleteFromCart(item.cartItemId))}
                    >
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4>
              Sub Total :{" "}
              <span className="shop-total">
                {currency.currencySymbol + price(subTotal)}
              </span>
            </h4>
            {totalDiscount > 0 && (
              <h4>
                Discount :{" "}
                <span className="shop-total">
                  {currency.currencySymbol + price(totalDiscount)}
                </span>
              </h4>
            )}
            {couponDiscountPrice > 0 && (
              <h4>
                Coupon Discount:{" "}
                <span className="shop-total">
                  {currency.currencySymbol + price(couponDiscountPrice)}
                </span>
              </h4>
            )}
            <h4>
              Tax :{" "}
              <span className="shop-total">
                ${price(taxAmount(cartTotalPrice))}
              </span>
            </h4>
            <h4>
              Grant Total :{" "}
              <span className="shop-total">
                ${price(+cartTotalPrice + taxAmount(cartTotalPrice))}
              </span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"}>
              view cart
            </Link>
            <Link
              className="default-btn"
              to={process.env.PUBLIC_URL + "/checkout"}
            >
              checkout
            </Link>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">No items added to cart</p>
      )}
    </div>
  );
};

export default MenuCart;
