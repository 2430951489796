import PropTypes from "prop-types";
import clsx from "clsx";
import Swiper, { SwiperSlide } from "../../components/swiper";
import CategoryFourSingle from "../../components/category/CategoryFourSingle.js";
import { useSelector } from "react-redux";
import { CategorySliderLoading } from "../../helpers/loadingBox";

const CategorySlider = ({ spaceTopClass, spaceBottomClass }) => {
  const { subcategories: categories } = useSelector(
    (state) => state.subcategory
  );
  const settings = {
    loop: true,
    navigation:true,
    
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
    },
  };
  return (
    <div className={clsx("collections-area", spaceTopClass, spaceBottomClass)}>
      <div className="container">
        <div className="collection-wrap ">
          <h1 className="text-center mb-5">Our Featured Collection</h1>
          <div
            className={`collection-active ${
              categories.length > 0 ? "category-buttons" : null
            }`}
          >
            {categories && (
              <Swiper options={settings}>
                {categories.map((single, key) => (
                  single.photo.length !== 0 &&
                  <SwiperSlide key={key}>
                    <CategoryFourSingle data={single} />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
            {categories.length === 0 && (
              <div className="row">
                <CategorySliderLoading count={4} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CategorySlider.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default CategorySlider;
