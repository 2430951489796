const { createSlice } = require("@reduxjs/toolkit");

const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    banners: [],
    loading: false,
  },
  reducers: {
    setBanner(state, action) {
      state.banners = action.payload;
      state.loading = false;
    },
    loadingBanner(state, action) {
      state.loading = true;
    },
  },
});

export const { setBanner, loadingBanner } = bannerSlice.actions;
export default bannerSlice.reducer;
