export const replaceWords = (asc) => {
  let data;
  if (
    asc?.match(
      /ASC925|ASC\s*925|America\s*Silver\s*Collection\s*925|America\s*Silver\s*Collection|America's?\s*Silver\s*Collections?/
    )
  ) {
    data = asc?.replace(
      /ASC925|ASC\s*925|America\s*Silver\s*Collection\s*925|America\s*Silver\s*Collection|America's?\s*Silver\s*Collections?/g,
      "Shopy blue"
    );
  }

  if (data === undefined) {
    return asc;
  } else {
    return data;
  }
};

export const shortDesc = (desc) => {
  let des = desc?.length > 100 ? desc.slice(0, 200) + "..." : desc;
  let data;
  if (
    des?.match(
      /ASC925|ASC\s*925|America\s*Silver\s*Collection\s*925|America\s*Silver\s*Collection|America's?\s*Silver\s*Collections?/
    )
  ) {
    data = des?.replace(
      /ASC925|ASC\s*925|America\s*Silver\s*Collection\s*925|America\s*Silver\s*Collection|America's?\s*Silver\s*Collections?/g,
      "Shopy blue"
    );
  }
  if (data === undefined) {
    return des;
  } else {
    return data;
  }
};

export const formatPhoneNumber = (input) => {
  const cleaned = input.replace(/\D/g, "");

  if (cleaned.length === 0) {
    return "";
  }

  let formattedPhoneNumber = "";
  if (cleaned.length >= 1) {
    formattedPhoneNumber += `${cleaned.slice(0, 3)}`;
  }
  if (cleaned.length >= 4) {
    formattedPhoneNumber += `-${cleaned.slice(3, 6)}`;
  }
  if (cleaned.length >= 7) {
    formattedPhoneNumber += `-${cleaned.slice(6, 10)}`;
  }

  formattedPhoneNumber = formattedPhoneNumber.replace(/--/g, "-");

  return formattedPhoneNumber;
};
