import React, { Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useState } from "react";
import { useEffect } from "react";
import cogoToast from "cogo-toast";
import axios from "axios";

const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

const ForgetPassword = () => {
  const { token } = useParams();
  let { pathname } = useLocation();
  const [verify, setVerify] = useState(false);
  const [error, setError] = useState(false);
  const redirect = useNavigate();
  const [Message, setMessage] = useState("");
  const [password, setPassword] = useState({
    password: "",
    passwordConfirm: "",
  });
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (token) {
      setVerify(true);
    }
  }, [token]);

  const handlePasswordChange = (e) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (!email) return cogoToast.error("Please enter your email.");

    if (!isEmail(email)) {
      cogoToast.error("Invalid email address");
      return;
    }

    setLoading(true);

    await axios
      .post(`https://dev.shopyblue.com/reset/password`, {
        email,
      })
      .then((res) => {
        cogoToast.success(res?.data?.message);
        setLoading(false);
        setEmail("");
      })
      .catch((err) => {
        cogoToast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    setLoading(true);

    await axios
      .post(`https://dev.shopyblue.com/reset/password/confirm`, {
        password: password.password,
        token,
      })
      .then((res) => {
        cogoToast.success(res.data.message);
        redirect("/login");
        setEmail("");
        setLoading(false);
      })
      .catch((err) => {
        cogoToast.error(err.response.data.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (token) {
      //   if the password is empty and password did not match then setError will be true
      if (password.password === "" || password.passwordConfirm === "") {
        setError(true);
        setMessage("");
      }
      //   if the password is not empty and password did not match then setError will be true
      else if (password.password !== password.passwordConfirm) {
        setError(true);
        setMessage("Password did not match");
      }
      //   if the password is not empty and password did match then setError will be false
      else if (password.password === password.passwordConfirm) {
        setError(false);
        setMessage("");
      } else {
        setError(false);
        setMessage("");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password.password, password.passwordConfirm]);

  return (
    <Fragment>
      <SEO
        titleTemplate={`${verify ? "Reset Password" : "Forget Password"}`}
        description={`${
          verify ? "Reset Password" : "Forget Password"
        } page of shop-blue react minimalist eCommerce template.`}
      />
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            {
              label: `Home`,
              path: process.env.PUBLIC_URL + "/",
            },
            {
              label: `${verify ? "Reset Password" : "Forget Password"}`,
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        <div className="login-register-area pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 ms-auto me-auto">
                <div className="login-register-wrapper">
                  <Tab.Container defaultActiveKey="login">
                    <Nav variant="pills" className="login-register-tab-list">
                      <Nav.Item>
                        <Nav.Link eventKey="login">
                          {verify ? (
                            <h4>Reset Password</h4>
                          ) : (
                            <h4>Forget Password</h4>
                          )}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="login">
                        {verify ? (
                          <div className="login-form-container">
                            <div className="login-register-form">
                              <form>
                                <label htmlFor="" className="mb-1">
                                  Password
                                </label>
                                <input
                                  type="password"
                                  name="password"
                                  className="mb-3"
                                  placeholder="Password"
                                  onChange={(e) => handlePasswordChange(e)}
                                  value={password.password}
                                />

                                <label htmlFor="" className="mb-1">
                                  Confirm Password
                                </label>
                                <input
                                  type="password"
                                  name="passwordConfirm"
                                  placeholder="Confirm Password"
                                  className="mb-3"
                                  onChange={(e) => handlePasswordChange(e)}
                                  value={password.passwordConfirm}
                                />
                                {Message && (
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {Message}
                                  </div>
                                )}

                                <div className="button-box">
                                  <button
                                    type="submit"
                                    className=""
                                    onClick={(e) => handleResetPassword(e)}
                                    disabled={error}
                                  >
                                    <span>
                                      {loading
                                        ? "Loading..."
                                        : "Reset Password"}
                                    </span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        ) : (
                          <div className="login-form-container">
                            <div className="login-register-form">
                              <form>
                                <label htmlFor="">
                                  Enter your email{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  placeholder="Email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />

                                <div className="button-box">
                                  <button
                                    onClick={handlePasswordReset}
                                    disabled={loading}
                                  >
                                    <span>
                                      {loading
                                        ? "Loading..."
                                        : "Reset Password"}
                                    </span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default ForgetPassword;
