import Skeleton from "react-loading-skeleton";

export const ProductLoadingBox = ({ count, col }) => {
  const skeletons = [];

  for (let i = 0; i < count; i++) {
    skeletons.push(
      <div className={`${col} my-3`} key={i}>
        <Skeleton height={200} width="100%" style={{ marginBottom: "1rem" }} />
        <Skeleton height={20} width="100%" style={{ marginBottom: "0.5rem" }} />
        <Skeleton height={20} width="30%" />
      </div>
    );
  }

  return <>{skeletons}</>;
};

export const CategoriesLoading = ({ count }) => {
  return (
    <Skeleton width="80%" count={count} style={{ marginBottom: "1rem" }} />
  );
};

export const CategorySliderLoading = ({ count }) => {
  const skeletons = [];

  for (let i = 0; i < count; i++) {
    skeletons.push(
      <div className="col-md-3" key={i}>
        <div className="collection-img">
          <Skeleton height={200} style={{ marginBottom: "1rem" }} />
        </div>
        <div className="collection-content text-center">
          <Skeleton
            height={20}
            width="30%"
            style={{ marginBottom: "0.5rem" }}
          />
          <Skeleton height={25} className="rounded-pill" width="40%" />
        </div>
      </div>
    );
  }

  return <>{skeletons}</>;
};
