import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

const PolicyImages = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const handleImageClick = (data) => {
        setSelectedData(data);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6">
                        <div className="single-banner zoom mb-30">
                            <Link to="#" >
                                <img src="/assets/img/home/ProtectionPlans.png" alt="" />
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="single-banner zoom mb-30">
                            <Link to="#" onClick={() => handleImageClick("Klarna: See payment terms. A higher initial payment may be required for some consumers. CA residents: Loans made or arranged pursuant to a California Financing Law license.For more information check the following link: https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_us/sliceitinx")}>
                                <img src="/assets/img/home/KLARNA.png" alt="" />
                            </Link>
                        </div>
                        <div className="single-banner zoom">
                            <Link to="#" className='mt-3' onClick={() => handleImageClick("Affirm: Rates from 0-36% APR. Payment options may be subject to an eligibility check and may not be available in all states. While Affirm doesn't charge late fees, other payment methods may do so. Options depend on your purchase amount, and a down payment may be required. Estimated payment amounts exclude taxes and shipping charges, which are added at checkout. Loans to California residents made or arranged pursuant to a California Finance Lenders Law license. Financing options through Affirm are provided by these lending partners: affirm.com/lenders. CA residents: Loans by Affirm Loan Services, LLC are made or arranged pursuant to a California Finance Lender license. For more information check the following link: https://www.affirm.com/licenses 'or' https://www.affirm.com/lenders  ")}>
                                <img src="/assets/img/home/Affirm.png" alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Image Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {selectedData}
                    </p>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PolicyImages;
