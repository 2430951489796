import React from 'react'
import SEO from '../../components/seo'
import LayoutOne from '../../layouts/LayoutOne'
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useLocation } from 'react-router-dom';

const ShippingPolicy = () => {
    let { pathname } = useLocation();

    const shippingPolicyMap = () => {
        const sections = [
            {
                title: "1. Shipping Methods and Timeframe",
                content: "   We offer reliable shipping methods, including USPS, to ensure the safe and timely delivery of your jewelry. Shipping times may vary depending on your location and the selected shipping option. We will provide you with tracking information once your order has been shipped.",
                // bullets: [
                //     "UPS (United Parcel Service)",
                //     "FedEx",
                //     "USPS (United States Postal Service)"
                // ],
            },
            {
                title: "2. Shipping Address",
                content: "Please ensure that you provide accurate and complete shipping information during the checkout process. Shopy Blue is not responsible for packages delivered to incorrect addresses provided by the customer.",
                // bullets: [
                //     "Same Day: Orders placed before 3:00 PM Central Time on business days (Monday to Thursday) will be shipped on the same day or next business day.",
                //     "Overnight: Orders placed before 3:00 PM Central Time on business days (Monday to Thursday) can be shipped for next-day delivery at an additional cost.",
                //     "Standard: Orders will be shipped within the same day or next business day (Monday-Friday) from the date of order placement.",
                //     "Saturday & Sunday: All orders received on Saturday and Sunday will be shipped on Monday or Tuesday based on the urgency."
                // ],
            },
            {
                title: "3. Shipping Costs",
                content: "Shipping costs will be calculated based on your order total and the chosen shipping method. Any applicable shipping fees will be displayed during the checkout process.",
            },
        ];

        return sections.map((section, index) => (
            <div key={index} className="my-3">
                <h3>{section.title}</h3>
                {section.content && <p>{section.content}</p>}
                {section.bullets && (
                    <ul>
                        {section.bullets.map((bullet, index) => (
                            <li key={index} className='ms-3'>- {bullet}</li>
                        ))}
                    </ul>
                )}
            </div>
        ));
    };





    return (
        <>
            <SEO
                titleTemplate="Shipping Policy | Shopy Blue"
                description="Shipping Policy page of  America's Silver Collection."
            />
            <LayoutOne headerTop="visible" headerContainerClass="container-fluid" headerPaddingClass="header-padding-1">
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        { label: "Shipping Policy", path: process.env.PUBLIC_URL + pathname }
                    ]}
                />
                <div className="error-area pt-40 pb-100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-10 col-lg-10">
                                <div className="error-wrapper">
                                    <h1>Shipping Policy</h1>
                                    <div className="error-text">
                                        <p>
                                            At Shopy Blue, we value your satisfaction with our jewelry products. Our Shipping and Return Policy outlines the guidelines and procedures for shipping and returns. By making a purchase with us, you agree to the terms and conditions outlined in this policy.
                                        </p>

                                        {shippingPolicyMap()}

                                        <p>
                                            If you have any questions or concerns about our Shipping Policy or how we handle your data, please contact us at:
                                        </p>
                                        <p>
                                            <strong>Shopy Blue</strong>
                                            <br />
                                            Phone: <a href="tel:+17139885400">713-988-5400</a>
                                            <br />
                                            Houston, TX 77057
                                            <br />
                                            Email: <a href="mailto:info@shopyblue.com">info@shopyblue.com</a>
                                        </p>
                                        <p>
                                            Thank you for choosing Shopy Blue for your jewelry needs. We are committed to providing a positive shopping experience and ensuring your satisfaction with our products and services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutOne>
        </>
    )
}

export default ShippingPolicy