const { createSlice } = require("@reduxjs/toolkit");

const addressSlice = createSlice({
  name: "address",
  initialState: {
    address: [],
    loading: false,
  },
  reducers: {
    setAddress(state, action) {
      state.address = action.payload;
      state.loading = false;
    },
    addNewAddress(state, action) {
      state.address = [...state.address, action.payload];
      state.loading = false;
    },
    updateAddress(state, action) {
      const { id, updatedAddress } = action.payload;
      const index = state.address.findIndex((address) => address.id === id);
      if (index !== -1) {
        state.address[index] = { ...state.address[index], ...updatedAddress };
      }
      state.loading = false;
    },
    deleteAddressById(state, action) {
      const idToDelete = action.payload;

      state.address = state.address.filter(
        (address) => address.id !== idToDelete
      );
      state.loading = false;
    },
    resetAddress(state) {
      state.address = [];
      state.loading = false;
    },

    addressLoading(state, action) {
      state.loading = true;
    },
    addressLoadingFalse(state, action) {
      state.loading = false;
    },
  },
});

export const {
  setAddress,
  addNewAddress,
  updateAddress,
  deleteAddressById,
  resetAddress,
  addressLoading,
  addressLoadingFalse,
} = addressSlice.actions;
export default addressSlice.reducer;
