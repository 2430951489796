import { Fragment } from "react";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import { useSelector } from "react-redux";
import HeroSliderSeventeen from "../../wrappers/hero-slider/HeroSliderSeventeen";
import CategorySlider from "../../wrappers/category/CategorySlider";
import BannerFifteen from "../../wrappers/banner/BannerFifteen";
import BannerFive from "../../wrappers/banner/BannerFive";
// import CountDownThree from "../../wrappers/countdown/CountDownThree";
import FeatureIconFour from "../../wrappers/feature-icon/FeatureIconFour";
import NewsletterThree from "../../wrappers/newsletter/NewsletterThree";
// import TestimonialOne from "../../wrappers/testimonial/TestimonialOne";
import TabProductTen from "../../wrappers/product/TabProductTen";
import CtaOne from "../../wrappers/cta/CtaOne";
import ProductSlider from "../../components/product/ProductSlider";
import ProductSlider2 from "../../components/product/ProductSlider2";
import PolicyImages from "../../components/home/PolicyImages";

const HomeHandmade = () => {

  const { products, loading } = useSelector((state) => state.product);
  const mostSearchedProducts = products.filter(product => product.mostSearched);
  const featureProducts = products.filter(product => product.isFeatured);

  return (
    <Fragment>
      <SEO titleTemplate="Shopy Blue" description="Home Page of Shopy Blue" />
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
        headerPositionClass="header-absolute"
      >
        {/* hero slider */}
        <HeroSliderSeventeen />

        {/* category */}
        <CategorySlider spaceTopClass="pt-100" spaceBottomClass="pb-95" />
        {/* banner */}
        <BannerFifteen spaceTopClass="pt-10" spaceBottomClass="pb-85" />

        {/* tab product */}
        <TabProductTen
          spaceBottomClass="pb-65"
          spaceTopClass="pt-70"
          category="Shopy Blue"
        />

        <BannerFive />

        {/* Product SLider 1 */}
        {
          mostSearchedProducts && mostSearchedProducts.length > 0 &&
          <ProductSlider
            products={mostSearchedProducts}
            loading={loading}
            sectionHeading={'Most Searched Products'}
          />
        }

        {
          featureProducts && featureProducts.length > 0 &&
          <ProductSlider2
            products={featureProducts}
            loading={loading}
            sectionHeading={'Feature Products'}
          />
        }
        {/* <ProductSlider
          products={mostSearchedProducts}
          loading={loading}
          sectionHeading={'Most Searched Products'}
        /> */}

        {/* <ProductSlider2
          products={featureProducts}
          loading={loading}
          sectionHeading={'Feature Products'}
        /> */}


        {/* <TabProductFour
          spaceBottomClass="pb-100"
          category="organic food"
          productTabClass="product-tab-fruits"
        /> */}

        <PolicyImages />

        <CtaOne
          spaceTopClass="pt-100 "
          backgroundImage="/assets/img/bg/cta.jpg "
        />

        {/* feature icon */}
        <FeatureIconFour
          bgImg="/assets/img/bg/shape.png"
          containerClass="container-fluid"
          gutterClass="padding-10-row-col"
          spaceTopClass="pt-50"
          spaceBottomClass="pb-40"
        />


        {/* countdown */}
        {/* <CountDownThree
          spaceTopClass="pt-100"
          spaceBottomClass="pb-100"
          dateTime="November 13, 2023 12:12:00"
          countDownImage="/assets/img/banner/deal-3.png"
        /> */}
        {/* testimonial */}
        {/* <TestimonialOne
          spaceTopClass="pt-100"
          spaceBottomClass="pb-95"
          spaceLeftClass="ml-70"
          spaceRightClass="mr-70"
          bgColorClass="bg-gray-3"
        /> */}
        {/* newsletter */}
        <NewsletterThree
          spaceTopClass="pt-100"
          spaceBottomClass="pb-100"
          subscribeBtnClass="dark-red-subscribe"
        />
      </LayoutOne>
    </Fragment>
  );
};

export default HomeHandmade;
