import React from 'react'
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom"

const SizeGuide = () => {
    let { pathname } = useLocation();
    return (

        <>
            <SEO
                titleTemplate="Size Guide | Shopy Blue"
                description="Size guide page of  Shopy Blue"
            />
            <LayoutOne headerTop="visible" headerContainerClass="container-fluid" headerPaddingClass="header-padding-1">
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        { label: "Size Guide", path: process.env.PUBLIC_URL + pathname }
                    ]}
                />
                <div className="error-area pt-40 pb-100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-10 col-lg-10">
                                <h1>
                                    Ring Size Guide
                                </h1>
                                <p>
                                    Choosing the right ring size is essential to ensure a comfortable fit for your jewelry. At Shopy Blue, we understand the importance of finding the perfect size, especially when it comes to rings and bands. To help you determine your ideal ring size, please follow our simple ring size guide below:
                                </p>
                                <h4>
                                    Method 1: Measuring an Existing Ring
                                </h4>
                                <p>
                                    If you already have a ring that fits you well and is similar in width to the one you intend to purchase, you can use the following steps to determine your ring size:<br />
                                    1. Find a ring that fits comfortably on the finger you plan to wear the new ring.<br />
                                    2. Place the ring on a ruler or a ring sizing guide with millimeter markings.<br />
                                    3. Measure the inside diameter of the ring from edge to edge, making sure to measure across the center of the ring.<br />
                                    4. Use the following chart to determine your ring size based on the inside diameter measurement:<br />
                                    - Inside Diameter (mm) | Ring Size<br />
                                    - 14.0mm | Size 3<br />
                                    - 14.4mm | Size 3.5<br />
                                    - 14.8mm | Size 4<br />
                                    - 15.2mm | Size 4.5<br />
                                    - 15.6mm | Size 5<br />
                                    - 16.0mm | Size 5.5<br />
                                    - 16.5mm | Size 6<br />
                                    - 16.9mm | Size 6.5<br />
                                    - 17.3mm | Size 7<br />
                                    - 17.7mm | Size 7.5<br />
                                    - 18.2mm | Size 8<br />
                                    - 18.6mm | Size 8.5<br />
                                    - 19.0mm | Size 9<br />
                                    - 19.4mm | Size 9.5<br />
                                    - 19.8mm | Size 10<br />
                                    - 20.2mm | Size 10.5<br />
                                    - 20.6mm | Size 11<br />
                                    - 21.0mm | Size 11.5<br />
                                    - 21.4mm | Size 12<br />

                                </p>
                                <h4>
                                    Method 2: Measuring Your Finger
                                </h4>
                                <p>
                                    If you don't have a ring to measure, you can determine your ring size by measuring your finger directly. Here's how:<br />
                                    1. Wrap a piece of string or a thin strip of paper around the base of the finger where you plan to wear the ring.<br />
                                    2. Mark where the string or paper overlaps to form a complete circle.<br />
                                    3. Measure the length of the string or paper from the marked point to the end using a ruler in millimeters.<br />
                                    4.Use the following chart to determine your ring size based on the circumference of your finger:<br />
                                    - Circumference (mm) | Ring Size<br />
                                    - 44.2mm | Size 3<br />
                                    - 46.8mm | Size 3.5<br />
                                    - 49.3mm | Size 4<br />
                                    - 51.9mm | Size 4.5<br />
                                    - 54.4mm | Size 5<br />
                                    - 57.0mm | Size 5.5<br />
                                    - 59.5mm | Size 6<br />
                                    - 62.1mm | Size 6.5<br />
                                    - 64.6mm | Size 7<br />
                                    - 67.2mm | Size 7.5<br />
                                    - 69.7mm | Size 8<br />
                                    - 72.3mm | Size 8.5<br />
                                    - 74.8mm | Size 9<br />
                                    - 77.4mm | Size 9.5<br />
                                    - 79.9mm | Size 10<br />
                                    - 82.5mm | Size 10.5<br />
                                    - 85.0mm | Size 11<br />
                                    - 87.6mm | Size 11.5<br />
                                    - 90.1mm | Size 12<br />
                                </p>
                                <p>
                                    Please note that finger sizes can vary slightly depending on the time of day and temperature. It's best to measure your finger at the end of the day when your fingers are slightly warmer and at their largest. If you're unsure about your ring size, we recommend visiting a local jeweler for a professional sizing.
                                </p>
                                <p>
                                    If you have any further questions or need assistance, please don't hesitate to contact our customer support team at 713-988-5400 or email us at info@shopyblue.com. We're here to help you find the perfect fit for your beautiful jewelry from Shopy Blue.
                                </p>


                            </div>
                        </div>
                    </div>
                </div>
            </LayoutOne>
        </>

    )
}

export default SizeGuide