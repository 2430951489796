import axios from "axios";
import { getCategories } from "../slices/category-slice";
const API_URL = process.env.REACT_APP_API_HOST;
export const fetchCategories = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_URL}/category`);
      dispatch(getCategories(response.data));
    } catch (error) {
      console.log(error);
    }
  };
};
