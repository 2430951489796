import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteAllFromCart } from "../../store/slices/cart-slice";
import { orderPayment } from "../../store/actions/orderActions";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import cogoToast from "cogo-toast";
import { resetOrder } from "../../store/slices/orderReducers";
import { resetCoupons } from "../../store/actions/couponActions";

const ThankYou = () => {
  let { pathname } = useLocation();
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.order);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const redirect_status = urlParams.get("redirect_status");
    const payment_intent_client_secret = urlParams.get(
      "payment_intent_client_secret"
    ),
      payment_intent = urlParams.get("payment_intent");

    if (
      redirect_status === "succeeded" &&
      payment_intent_client_secret &&
      payment_intent
    ) {
      dispatch(orderPayment(order._id, payment_intent_client_secret));
      dispatch(deleteAllFromCart());
      dispatch(resetOrder());
      dispatch(resetCoupons());
      window.history.replaceState({}, document.title, "/");
      cogoToast.success("Order Placed Successfully!", {
        position: "top-center",
      });
    } else {
      cogoToast.error("Something went wrong!");
      redirect("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <SEO
        titleTemplate="Thank You | Shopy Blue"
        description="Thank You page of Shopy Blue."
      />
      <LayoutOne
        headerTop="visible"
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            { label: "Thank You", path: process.env.PUBLIC_URL + pathname },
          ]}
        />
        <div className="container">
          <div className="row justify-content-center align-items-center my-5 py-5">
            <div className="col-md-6 text-center">
              <h1>
                <video
                  src="/lottie.mp4"
                  loop
                  autoPlay
                  className="img-fluid"
                  style={{ mixBlendMode: "multiply" }}
                  width={"50%"}
                />
              </h1>
              <p>For ordering! we have received your order.</p>
              <Link to="/">
                <div className="slider-btn slider-btn--style2 btn-hover">
                  <Link
                    className="animated rounden-btn p-2"
                    to={process.env.PUBLIC_URL + "/"}
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    Back to Home
                  </Link>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default ThankYou;
