import { configureStore, combineReducers } from "@reduxjs/toolkit";
import productReducer from "./slices/product-slice";
import currencyReducer from "./slices/currency-slice";
import cartReducer from "./slices/cart-slice";
import compareReducer from "./slices/compare-slice";
import wishlistReducer from "./slices/wishlist-slice";
import categorySlice from "./slices/category-slice";
import subcategorySlice from "./slices/subcategory-slice";
import authSlice from "./slices/auth-slice";
import { loadState } from "../helpers/persistor";
import addressSlice from "./slices/address-slice";
import orderReducers from "./slices/orderReducers";
import couponSlice from "./slices/coupon-slice";
import bannerSlice from "./slices/banner-slice";

const persistedState = loadState();

export const rootReducer = combineReducers({
  product: productReducer,
  currency: currencyReducer,
  cart: cartReducer,
  compare: compareReducer,
  wishlist: wishlistReducer,
  category: categorySlice,
  subcategory: subcategorySlice,
  auth: authSlice,
  address: addressSlice,
  order: orderReducers,
  coupon: couponSlice,
  banner: bannerSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
