import React from 'react'
import SEO from '../../components/seo'
import LayoutOne from '../../layouts/LayoutOne'
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useLocation } from 'react-router-dom';

const ReturnPolicy = () => {
    let { pathname } = useLocation();

    const returnPolicyMap = () => {
        const sections = [
            {
                title: "1. Authorization and Timeframe",
                subsections: [
                    {
                        title: "1.1 Authorization",
                        content:
                            "   All returns and exchanges require prior authorization from Shopy Blue. To initiate a return or exchange, please contact our customer support team at 713-988-5400 or email us at info@shopyblue.com to obtain a Return Authorization (RA) number. Returns sent without a valid RA number will not be accepted.",
                    },
                    {
                        title: "1.2 Timeframe",
                        content:
                            "Returns must be initiated within 7 days of the original purchase date. Any returns received after this timeframe may be subject to denial.",
                    },
                ],
            },
            {
                title: "2. Return Eligibility",
                subsections: [
                    {
                        title: "2.1 Condition of Items",
                        content:
                            "Returned items must be in their original, unused, and undamaged condition, accompanied by all original packaging, tags, and documentation. Shopy Blue reserves the right to refuse returns of items that are not in resalable condition.",
                    },
                    {
                        title: "2.2 Non-Returnable Items",
                        content:
                            "The following items are non-returnable:",
                        bullets: [
                            "Customized or personalized items",
                            "Items that have been worn, altered, scratched, damaged, or show wear and tear",
                            "Earrings (for hygiene reasons)",
                            "Special order items"
                        ],
                    },
                ],
            },
            {
                title: "3. Restocking Charges and Return Check Fee",
                subsections: [
                    {
                        title: "3.1 Restocking Charges",
                        content:
                            "A restocking fee of 15% will be applied to all eligible returns. This fee will be deducted from the refund amount or store credit issued.",
                    },
                    {
                        title: "3.2 Return Check Fee",
                        content:
                            "In the event of a returned payment or insufficient funds, a return check fee of $38.50 will be charged to cover administrative costs.",
                    },
                ],
            },
            {
                title: "4. Return Process",
                subsections: [
                    {
                        title: "4.1 Requesting Return Authorization",
                        content:
                            "To request a return or exchange, please contact our customer support team within the specified timeframe to obtain a Return Authorization (RA) number. Provide the following information:",
                        bullets: [
                            "Order number",
                            "Item(s) being returned",
                            "Earrings (for hygiene reasons)",
                            "Reason for return/exchange"
                        ],
                    },
                    {
                        title: "4.2 Packaging and Shipment",
                        content:
                            "Pack the item securely in the original packaging, along with all accessories and documentation. Ship the return via a reliable and traceable shipping method to the address provided with the RA number. The buyer is responsible for all return shipping costs.",
                    },
                    {
                        title: "4.3 Return Approval and Refund",
                        content:
                            "Once the returned item is received and inspected, we will notify you of the return approval status. If the return is approved, a refund will be issued in the original form of payment, minus any applicable restocking charges or return check fee. Alternatively, store credit may be offered as per the buyer's preference.",
                    },
                ],
            },
            {
                title: "5. Payment Guarantee and Liability",
                subsections: [
                    {
                        title: "5.1 Payment Guarantee",
                        content:
                            "The undersigned personally guarantees payment of any outstanding balances incurred by the above company. In the event of default on payment, the undersigned agrees to be personally liable for all outstanding balances.",
                    },
                    {
                        title: "5.2 Authorization to Charge Credit Card",
                        content:
                            "The undersigned authorizes Shopy Blue to charge the provided credit card for any owed amounts and accepts all terms and conditions of the card issuer.",
                    },
                    {
                        title: "5.3 Collection Costs",
                        content:
                            "If the above account becomes delinquent and is placed in the hands of an attorney or collection agency, the buyer agrees to pay attorney or collection agency fees, court costs, and any other costs associated with the collection.",
                    }
                ]
            },

            {
                title: "6. Jurisdiction and Venue",
                subsections: [
                    {
                        title: "6.1 Disputes",
                        content:
                            "Any disputes arising from this policy or related transactions shall be subject to the jurisdiction and venue of the courts in Harris County, Texas.",

                    },
                ]
            }
        ];


        return sections.map((section, index) => (
            <>
                <h3 key={index}>{section.title}</h3>
                <p>
                    {section.content}
                </p>
                {section.subsections.map((subsection, index) => (
                    <>
                        <div className='ms-4 my-2' key={index}>
                            <h4>{subsection.title}</h4>
                            <p>{subsection.content}</p>
                        </div>
                        {subsection.bullets && (
                            <ul className='mb-3'>
                                {subsection.bullets.map((bullet, index) => (
                                    <li key={index} className='ms-3'>- {bullet}</li>
                                ))}
                            </ul>
                        )}
                    </>
                ))}

            </>
        ));
    };





    return (
        <>
            <SEO
                titleTemplate="Return & Exchange Policy | Shopy Blue"
                description="Return & Exchange Policy page of  Shopy Blue."
            />
            <LayoutOne headerTop="visible" headerContainerClass="container-fluid" headerPaddingClass="header-padding-1">
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        { label: "Return & Exhange Policy", path: process.env.PUBLIC_URL + pathname }
                    ]}
                />
                <div className="error-area pt-40 pb-100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-10 col-lg-10">
                                <div className="error-wrapper">
                                    <h1>Returns and Exchange Policy</h1>
                                    <div className="error-text">

                                        {returnPolicyMap()}

                                        <p>
                                            For further assistance or inquiries regarding shipping and returns, please contact our customer support team:
                                        </p>
                                        <p>
                                            <strong>Shopy Blue</strong>
                                            <br />
                                            Phone: <a href="tel:+17139885400">713-988-5400</a>
                                            <br />
                                            Houston, TX 77057
                                            <br />
                                            Email: <a href="mailto:info@shopyblue.com">info@shopyblue.com</a>
                                        </p>
                                        <p>
                                            Thank you for choosing Shopy Blue for your jewelry needs. We are committed to providing a positive shopping experience and ensuring your satisfaction with our products and services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutOne>
        </>
    )
}

export default ReturnPolicy