import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import { useSelector } from "react-redux";
import ProductGridSingle from './ProductGridSingle';
import { ProductLoadingBox } from "../../helpers/loadingBox";


const ProductSlider2 = ({ products, loading, sectionHeading }) => {

    const currency = useSelector((state) => state.currency);
    const { cartItems } = useSelector((state) => state.cart);
    const { wishlistItems } = useSelector((state) => state.wishlist);
    const { compareItems } = useSelector((state) => state.compare);

    return (
        <>
            <div className="container product-slider-navigation">
                <h1 className="text-center mb-5">{sectionHeading}</h1>
                {
                    !loading ?
                        (
                            <>
                                <Swiper navigation={true}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 2,
                                        },
                                        576: {
                                            slidesPerView: 2,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                        },
                                        992: {
                                            slidesPerView: 4,
                                        },
                                    }}
                                    modules={[Autoplay, Navigation]} slidesPerView={4} autoplay={{ delay: 7000 }} spaceBetween={20}
                                >
                                    {products.map((product) => (
                                        <SwiperSlide key={product.id}>
                                            <ProductGridSingle
                                                product={product}
                                                currency={currency}
                                                spaceBottomClass="mb-25"
                                                cartItem={cartItems.find(
                                                    (cartItem) => cartItem.id === product?.id
                                                )}
                                                wishlistItem={wishlistItems?.find(
                                                    (wishlistItem) => wishlistItem.id === product?.id
                                                )}
                                                compareItem={compareItems.find(
                                                    (compareItem) => compareItem.id === product?.id
                                                )}
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </>
                        ) : (
                            <>
                                <div className="row">
                                    <ProductLoadingBox
                                        count={12}
                                        col={"col-xl-3 col-md-6 col-lg-4 col-sm-6"}
                                    />
                                </div>
                            </>
                        )
                }

            </div>

        </>
    )
}

export default ProductSlider2
