import React from "react";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom";

const PrivacyPolicy = () => {
  let { pathname } = useLocation();

  const privacyPolicyMap = () => {
    const sections = [
      {
        title: "1. Information We Collect",
        content: "We collect various types of information to provide you with our services, process your orders, and improve your shopping experience. The information we collect may include.",
        subsections: [
          {
            title: "1.1 Personal Identification Information",
            content:
              "This includes your first and last name, email address, phone number, and other contact information you provide to us voluntarily.",
          },
          {
            title: "1.2 Shipping Information",
            content:
              "We collect your shipping address to ensure the accurate delivery of your purchased items.",
          },
          {
            title: "1.3 Billing Information",
            content:
              "When you make a purchase, we collect payment details, such as credit card information or other payment methods, to process your order securely.",
          },
          {
            title: "1.4 Device Information",
            content:
              "We may collect information about the device you use to access our website, including IP address, browser type, and operating system.",
          },
        ],
      },
      {
        title: "2. How We Use Your Information",
        content: "We use the information we collect for various purposes, including but not limited to",
        subsections: [
          {
            title: "2.1 Processing Orders",
            content:
              "We use your information to process and fulfill your orders, including billing and shipping.",
          },
          {
            title: "2.2 Customer Support",
            content:
              "We may use your contact information to respond to your inquiries, provide customer support, and address any issues or concerns.",
          },
          {
            title: "2.3 Improving Our Services",
            content:
              "We analyze data to enhance our website's functionality, content, and design to provide a better shopping experience.",
          },
          {
            title: "2.4 Marketing and Promotions",
            content:
              "With your consent, we may use your email address to send you promotional offers, newsletters, and updates about our products and services. You can opt out of these communications at any time.",
          },
        ],
      },
      {
        title: "3. Security and Data Protection",
        content: "We take the security of your data seriously and implement measures to protect it from unauthorized access, disclosure, alteration, or destruction. Our website uses encryption technology (SSL) to secure your sensitive information during online transactions.",
      },
      {
        title: "4. Third-Party Services",
        content:
          "We may share your information with trusted third-party service providers, such as shipping companies, payment processors, and analytics providers, to facilitate our services. These third parties are required to adhere to strict privacy and security standards.",
      },
      {
        title: "5. Cookies and Tracking Technologies",
        content:
          "We use cookies and similar tracking technologies to enhance your browsing experience on our website. You can manage your cookie preferences through your web browser settings.",
      },
      {
        title: "6. Children's Privacy",
        content:
          "Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from minors. If you believe we have unintentionally collected data from a child, please contact us immediately to delete the information.",
      },
      {
        title: "7. Changes to Privacy Policy",
        content:
          "We may update our Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on this page, and the date of the latest revision will be indicated at the top of the policy.",
      },
    ];

    return sections.map((section, index) => (
      <div key={index} className="my-2">
        <h3 className="my-2">{section.title}</h3>
        {section.subsections &&
          section.subsections.map((subsection, index) => (
            <div className="ms-4 my-2" key={index}>
              <h4 className="my-2">{subsection.title}</h4>
              <p className="my-2">{subsection.content}</p>
            </div>
          ))}
        {section.content && <p>{section.content}</p>}
      </div>
    ));
  };

  return (
    <>
      <SEO
        titleTemplate="Privacy Policy | Shopy Blue"
        description="Privacy Policy page of  Shopy Blue."
      />
      <LayoutOne
        headerTop="visible"
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            {
              label: "Privacy Policy",
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        <div className="error-area pt-40 pb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-10">
                <div className="error-wrapper">
                  <h1>Privacy Policy</h1>
                  <div className="error-text">
                    <p>
                      At Shopy Blue, we are committed to ensuring the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you interact with our online jewelry store.
                    </p>
                    <p>
                      By using our website or providing us with your information, you consent to the practices described in this Privacy Policy. Please read it carefully to understand how we handle your data.
                    </p>

                    {privacyPolicyMap()}

                    <p>
                      If you have any questions or concerns about our Privacy Policy or how we handle your data, please contact us at:
                    </p>
                    <p>
                      <strong>Shopy Blue</strong>
                      <br />
                      Phone: <a href="tel:+17139885400">713-988-5400</a>
                      <br />
                      Houston, TX 77057
                      <br />
                      Email: <a href="mailto:info@shopyblue.com">info@shopyblue.com</a>
                    </p>
                    <p>
                      Thank you for trusting Shopy Blue with your personal information. We are committed to protecting your privacy and ensuring a secure and enjoyable shopping experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </>
  );
};

export default PrivacyPolicy;
