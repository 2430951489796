import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CategoryFourSingle = ({ data }) => {
  return (
    <div className="collection-product">
      <div className="collection-img">
        <Link
          to={`${process.env.PUBLIC_URL}/category/${data.category.slug}/${data.slug}`}
        >
          <img
            src={
              process.env.PUBLIC_URL + data.photo ||
              "/assets/img/placeholder.png"
            }
            alt=""
          />
        </Link>
      </div>
      <div className="collection-content text-center">
        <span>{data.name}</span>
        <h4>
          <Link
            to={`${process.env.PUBLIC_URL}/category/${data.category.slug}/${data.slug}`}
          >
            {data.title}
          </Link>
        </h4>
        <Link
          to={`${process.env.PUBLIC_URL}/category/${data.category.slug}/${data.slug}`}
          className="collection-btn"
        >
          SHOP NOW
        </Link>
      </div>
    </div>
  );
};

CategoryFourSingle.propTypes = {
  data: PropTypes.shape({}),
  sliderClass: PropTypes.string,
};

export default CategoryFourSingle;
