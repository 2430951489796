import { useState, useEffect } from "react";

const MediaQuery = (width) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${width})`);

    const handleMediaQueryChange = (e) => {
      setIsSmallScreen(e.matches);
    };

    mediaQuery.addListener(handleMediaQueryChange);

    setIsSmallScreen(mediaQuery.matches);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isSmallScreen };
};

export default MediaQuery;
