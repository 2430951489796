import PropTypes from "prop-types";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";

const BreadcrumbWrap = ({ pages }) => {
  return (
    <div className="breadcrumb-area pt-35 pb-35 bg-gray-3">
      <div className="container">
        <Breadcrumb>
          {pages
            .filter((item) => item?.path !== undefined)
            ?.map(({ path, label }, i) => {
              return (
                <Breadcrumb.Item
                  key={i}
                  linkProps={{ to: path }}
                  linkAs={Link}
                  active={i === pages.length - 1}
                >
                  {label}
                </Breadcrumb.Item>
              );
            })}
        </Breadcrumb>
      </div>
    </div>
  );
};

BreadcrumbWrap.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default BreadcrumbWrap;
