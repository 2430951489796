const { createSlice } = require("@reduxjs/toolkit");

const subCategorySlice = createSlice({
  name: "subcategory",
  initialState: {
    subcategories: [],
  },

  reducers: {
    getSubCategories(state, action) {
      state.subcategories = action.payload;
    },
  },
});

export const { getSubCategories } = subCategorySlice.actions;
export default subCategorySlice.reducer;
