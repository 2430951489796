import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchProducts } from "./store/actions/productActions";
import { fetchCategories } from "./store/actions/categoryActions";
import { fetchSubCategories } from "./store/actions/subCategoryActions";
import "react-loading-skeleton/dist/skeleton.css";
import { getAddress } from "./store/actions/addressAction";
import { myOrders } from "./store/actions/orderActions";
import ThankYou from "./pages/other/ThankYou";
import MyOrders from "./pages/other/MyOrders";
import StoreCart from "./helpers/StoreCart";
import { resetCoupons } from "./store/actions/couponActions";
import Login from "./pages/other/Login";
import ReturnPolicy from "./pages/other/ReturnPolicy";
import PrivacyPolicy from "./pages/other/PrivacyPolicy";
import ShippingPolicy from "./pages/other/ShippingPolicy";
import SizeGuide from "./pages/other/SizeGuide";
import StoreLocator from "./pages/other/StoreLocator";
import cogoToast from "cogo-toast";
import { getBanners } from "./store/actions/bannerActions";
import "react-lazy-load-image-component/src/effects/blur.css";
import ForgetPassword from "./pages/other/ForgetPassword";

const HomeHandmade = lazy(() => import("./pages/home/HomeHandmade"));
const Shop = lazy(() => import("./pages/shop/Shop"));
const Product = lazy(() => import("./pages/shop-product/Product"));
// const ProductTabLeft = lazy(() =>
//   import("./pages/shop-product/ProductTabLeft")
// );

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const Payment = lazy(() => import("./pages/other/Payment"));


const App = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { banners } = useSelector((state) => state.banner);
  const { cartItems } = useSelector((state) => state.cart);
  const { productsInCoupon } = useSelector((state) => state.coupon);
  const onlineStatus = navigator.onLine;

  useEffect(() => {
    dispatch(getBanners());
    if (banners) {
      if (isAuthenticated) {
        dispatch(getAddress());
        dispatch(myOrders());
      }
      dispatch(fetchProducts());
      dispatch(fetchCategories());
      dispatch(fetchSubCategories());
    }

    // eslint-disable-next-line
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (cartItems.length === 0) {
      dispatch(resetCoupons());
    } else {
      const filterProducts = cartItems?.filter((item) => {
        return productsInCoupon?.includes(item._id);
      });
      if (filterProducts?.length === 0) {
        dispatch(resetCoupons());
      }
    }

    // eslint-disable-next-line
  }, [cartItems]);

  useEffect(() => {
    if (!onlineStatus) {
      cogoToast.error("Internet connection has been lost!");
    }
  }, [onlineStatus]);

  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="shop-blue-preloader-wrapper">
              <div className="shop-blue-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Routes>
            <Route
              path={process.env.PUBLIC_URL + "/"}
              element={<HomeHandmade />}
            />

            {/* Shop page */}
            <Route
              path={process.env.PUBLIC_URL + "/shop"}
              element={<Shop category="all" />}
            />

            <Route
              path={process.env.PUBLIC_URL + `/category/:category`}
              element={<Shop />}
            />

            <Route
              path={process.env.PUBLIC_URL + `/category/:category/:subcategory`}
              element={<Shop />}
            />

            {/* Single Product */}

            <Route
              path={process.env.PUBLIC_URL + "/product/:slug"}
              element={<Product />}
            />
            {/* <Route
              path={process.env.PUBLIC_URL + "/product/:slug"}
              element={<ProductTabLeft />}
            /> */}

            {/* Shop MetalType */}
            <Route
              path={process.env.PUBLIC_URL + `/shop/metal-type/:metalType/`}
              element={<Shop />}
            />
            <Route
              path={process.env.PUBLIC_URL + `/shop/:type/`}
              element={<Shop />}
            />

            {/* Other pages */}
            <Route
              path={process.env.PUBLIC_URL + "/about"}
              element={<About />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/contact"}
              element={<Contact />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/my-account"}
              element={<MyAccount />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/register"}
              element={<LoginRegister />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/login"}
              element={<Login />}
            />

            <Route path={process.env.PUBLIC_URL + "/cart"} element={<Cart />} />
            <Route
              path={process.env.PUBLIC_URL + "/wishlist"}
              element={<Wishlist />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/compare"}
              element={<Compare />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/checkout"}
              element={<Checkout />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/payment"}
              element={<Payment />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/thankyou"}
              element={<ThankYou />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/my-orders"}
              element={<MyOrders />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/sizeguide"}
              element={<SizeGuide />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/return-policy"}
              element={<ReturnPolicy />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/privacy-policy"}
              element={<PrivacyPolicy />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/shipping-policy"}
              element={<ShippingPolicy />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/store-location"}
              element={<StoreLocator />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/forget-password"}
              element={<ForgetPassword />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/forget-password/:token"}
              element={<ForgetPassword />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
      <StoreCart />
    </Router>
  );
};

export default App;
