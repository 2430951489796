import React from "react";
import { connect } from "react-redux";
import HomeHandmade from "../pages/home/HomeHandmade";

const WithAuth = (Component) => {
  const AuthenticatedComponent = ({ isAuthenticated, ...props }) => {
    if (isAuthenticated) {
      return <Component {...props} />;
    } else {
      return <HomeHandmade />;
    }
  };

  const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
  });

  return connect(mapStateToProps)(AuthenticatedComponent);
};

export default WithAuth;
