

const MobileWidgets = () => {
  return (
    <div className="offcanvas-widget-area">
      <div className="off-canvas-contact-widget">
        <div className="header-contact-info">
          <ul className="header-contact-info__list">
            <li>
              <i className="fa fa-phone"></i>{" "}
              <a href="tel:+17139885400">713-988-5400</a>
            </li>
            <li>
              <i className="fa fa-envelope"></i>{" "}
              <a href="mailto:info@shopyblue.com">info@shopyblue.com</a>
            </li>
          </ul>
        </div>
      </div>
      {/*Off Canvas Widget Social Start*/}
      <div className="off-canvas-widget-social">
        <a href="https://www.youtube.com/channel/UCeY7Kauv6f9Gh-gHmpraOUA" title="Twitter">
          <i className="fa fa-youtube"></i>
        </a>
        <a href="https://instagram.com/shopy.blue?igshid=MzRlODBiNWFlZA==" title="Instagram">
          <i className="fa fa-instagram"></i>
        </a>
        <a href="https://www.facebook.com/shopyblueofficial?mibextid=LQQJ4d" title="Facebook">
          <i className="fa fa-facebook"></i>
        </a>
        <a href="https://www.pinterest.com/social6658/" title="Pinterest">
          <i className="fa fa-pinterest"></i>
        </a>
      </div>
      {/*Off Canvas Widget Social End*/}
    </div>
  );
};

export default MobileWidgets;
