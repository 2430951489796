import React, { Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import cogoToast from "cogo-toast";
import { userLogin } from "../../store/actions/authActions";
import PasswordField from "../../components/input/PasswordField";

const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

const Login = () => {
  let { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirect, error, loading } = useSelector((state) => state.auth);
  const [errors, setError] = useState({});

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const handleLoginInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;

    setLoginForm((prevForm) => ({
      ...prevForm,
      [name]: inputValue,
    }));
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    if (!loginForm.email || !loginForm.password)
      return cogoToast.error("Please fill all the fields.");

    if (!isEmail(loginForm.email)) {
      setError({ loginEmail: "Invalid email address" });
      cogoToast.error("Invalid email address");
    } else {
      setError({});
      dispatch(userLogin(loginForm));
    }

    // Handle login form submission
  };

  useEffect(() => {
    if (redirect) {
      navigate("/my-account");
    }

    // eslint-disable-next-line
  }, [redirect]);

  useEffect(() => {
    if (error) {
      cogoToast.error(error);
    }
  }, [error]);

  return (
    <Fragment>
      <SEO
        titleTemplate="Login"
        description="Login page of shop-blue react minimalist eCommerce template."
      />
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            {
              label: "Login",
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        <div className="login-register-area pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 ms-auto me-auto">
                <div className="login-register-wrapper">
                  <Tab.Container defaultActiveKey="login">
                    <Tab.Content>
                      <Tab.Pane eventKey="login">
                        <div className="login-form-container">
                          <div className="row mb-5">
                            <div className="col-md-6 d-flex gap-3 align-items-center">
                              <img
                                src="/assets/img/logo/Shopy-blue.png"
                                className="img-fluid w-25"
                                alt=""
                              />
                              <p>Shopy Blue</p>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-md-12 ps-3">
                              <h3>Login</h3>
                              <p>Continue Your Journey With Shopy Blue.</p>
                            </div>
                          </div>
                          <div className="login-register-form">
                            <form>
                              <label>Email</label>
                              <input
                                type="text"
                                name="email"
                                placeholder="Email"
                                value={loginForm.email}
                                className={errors.loginEmail && "border-danger"}
                                onChange={handleLoginInputChange}
                              />
                              <div className="login-toggle-btn d-flex justify-content-between">
                                <label>Password</label>

                                <Link
                                  to={
                                    process.env.PUBLIC_URL + "/forget-password"
                                  }
                                >
                                  <small>Forgot Password?</small>
                                </Link>
                              </div>
                              <PasswordField
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={loginForm.password}
                                onChange={handleLoginInputChange}
                              />
                              <div className="button-box w-100">
                                <button
                                  onClick={handleLoginSubmit}
                                  disabled={loading}
                                  className="w-100"
                                >
                                  <span>
                                    {loading ? "Loading..." : "Login"}
                                  </span>
                                </button>
                              </div>
                              <div className="login-toggle-btn mt-4 text-center">
                                <span> Don't have an account? </span>
                                <Link to={process.env.PUBLIC_URL + "/register"}>
                                  Register Now
                                </Link>
                              </div>
                            </form>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default Login;
