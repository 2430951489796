import { useState } from "react";
import { useNavigate } from "react-router-dom";

const MobileSearch = ({ closeMobileMenu }) => {
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  return (
    <div className="offcanvas-mobile-search-area">
      <form>
        <input
          type="search"
          placeholder="Search ..."
          onChange={(e) => setSearch(e.target.value)}
        />
        <button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            window.history.replaceState({}, document.title, "/shop");

            setTimeout(() => {
              navigate(`/shop?query=${search}`);
              closeMobileMenu();
            }, 500);
          }}
        >
          <i className="fa fa-search" />
        </button>
      </form>
    </div>
  );
};

export default MobileSearch;
