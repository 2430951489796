import axios from "axios";
import { setBanner, loadingBanner } from "../slices/banner-slice";

const API_URL = process.env.REACT_APP_API_HOST;

export const getBanners = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingBanner(true));
      await axios
        .get(`${API_URL}/banner`)
        .then((res) => {
          dispatch(setBanner(res.data));
        })
        .catch((err) => {
          loadingBanner(false);
        });
    } catch (error) {
      loadingBanner(false);
    }
  };
};
