import React from "react";
import { Fragment } from "react";
import SEO from "../../components/seo";
import { Link, useLocation } from "react-router-dom";
import LayoutOne from "../../layouts/LayoutOne";
import { useSelector } from "react-redux";
import moment from "moment";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
// import { myOrders as Orders } from "../../store/actions/orderActions";
import WithAuth from "../../wrappers/ProtectedRoutes";
import { getDiscountPrice, price, taxAmount } from "../../helpers/product";

const MyOrders = () => {
  let { pathname } = useLocation();
  const { myOrders } = useSelector((state) => state.order);
  const statusOrder = {
    Processing: "warning",
    Shipped: "info",
    Delivered: "success",
    Cancelled: "danger",
    Received: "info",
  };

  return (
    <Fragment>
      <SEO
        titleTemplate="My Orders"
        description="My Orders of Shopy Blue."
      />
      <LayoutOne
        headerTop="visible"
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            {
              label: "My Orders",
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        <div className="container my-5 py-5">
          {myOrders.length > 0 ? (
            <div className="row">
              <h1 className="fw-light">My Orders</h1>
              {myOrders?.map((order, index) => {
                return (
                  <div
                    className="col-lg-12 col-md-12 ml-auto mr-auto my-5"
                    key={index}
                  >
                    <div className="card bg-white shadow p-3">
                      <div className="card-header d-flex justify-content-between align-items-center bg-white flex-column flex-lg-row gap-2">
                        <h4 className=" p-3 bg-gray shadow-sm rounded-pill">
                          Order Id :{" "}
                          <span className="text-primary">#{order?.id}</span>
                        </h4>
                        <h4 className="text-center">
                          Order Placed :
                          <span> {moment(order?.createdAt).calendar()}</span>
                        </h4>
                        <h4 className="text-center">
                          Status
                          <span
                            className={`badge bg-${
                              statusOrder[order?.status]
                            } rounded-pill ms-2`}
                          >
                            {order?.status}
                          </span>
                        </h4>
                      </div>

                      <div className="card-body mt-4">
                        <div className="row d-flex align-items-center">
                          {order?.orderItems.map((items) => {
                            const discountedPrice = getDiscountPrice(
                              items.price,
                              items.discount
                            );
                            return (
                              <div className="col-lg-6 col-md-6 my-3">
                                <div className="row  align-items-center">
                                  <div className="col-md-4">
                                    <img
                                      src={items.image}
                                      alt=""
                                      className="w-100 orderImages rounded-2 mb-2"
                                    />
                                  </div>
                                  <div className="col-md-8">
                                    <h4 className="fs-5">{items.name}</h4>
                                    <p className="text-muted d-flex gap-4">
                                      <span>Quantity: {items.qty}</span>
                                      {items.ringSize &&
                                        items.ringSize !== "" && (
                                          <span>
                                            Ring Size: {items.ringSize}
                                          </span>
                                        )}
                                    </p>
                                    <div>
                                      {discountedPrice > 0 ? (
                                        <p className="text-muted d-flex gap-4">
                                          <del>${price(items.price)}</del>
                                          <span className="text-dark">
                                            ${price(discountedPrice)}
                                          </span>
                                        </p>
                                      ) : (
                                        <p className="text-muted">
                                          ${price(items.price)}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="card-footer bg-white d-flex justify-content-center gap-4 pt-4 flex-wrap">
                        {order?.subTotal > 0 && (
                          <h4>
                            {" "}
                            Sub Total:{" "}
                            <span className="text-muted">
                              ${price(order?.subTotal)}
                            </span>
                          </h4>
                        )}
                        {order?.totalDiscount > 0 && (
                          <h4>
                            {" "}
                            Discount:{" "}
                            <span className="text-muted">
                              ${price(order?.totalDiscount)}
                            </span>
                          </h4>
                        )}
                        {order?.couponDiscountPrice > 0 && (
                          <h4>
                            {" "}
                            Coupon Discount:{" "}
                            <span className="text-muted">
                              ${price(order?.couponDiscountPrice)}
                            </span>
                          </h4>
                        )}

                        <h4>
                          {" "}
                          Tax:{" "}
                          <span className="text-muted">
                            ${price(taxAmount(order?.totalPrice))}
                          </span>
                        </h4>
                        <h4>
                          {" "}
                          Total:{" "}
                          <span className="text-primary">
                            ${price(order?.itemsPrice)}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="item-empty-area text-center">
                  <div className="item-empty-area__icon mb-30">
                    <i className="pe-7s-cart"></i>
                  </div>
                  <div className="item-empty-area__text">
                    No Order Found <br />{" "}
                    <Link to={process.env.PUBLIC_URL + "/shop"}>Shop Now</Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default WithAuth(MyOrders);
