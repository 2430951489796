import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BannerFifteenSingle = ({ data, spaceBottomClass }) => {
  return (
    <div className={clsx("single-banner shadow rounded-3", spaceBottomClass)}>
      <Link to={process.env.PUBLIC_URL + data.link}>
        <LazyLoadImage
          src={process.env.PUBLIC_URL + data.image}
          alt=""
          style={{
            height: "300px",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </Link>
      <div className="banner-content banner-content--style2">
        <h3>{data.title}</h3>
        <h4>{data.subtitle}</h4>
        <Link to={process.env.PUBLIC_URL + data.link}>
          <i className="fa fa-long-arrow-right" />
        </Link>
      </div>
    </div>
  );
};

BannerFifteenSingle.propTypes = {
  data: PropTypes.shape({}),
  spaceBottomClass: PropTypes.string,
};

export default BannerFifteenSingle;
