const { createSlice } = require("@reduxjs/toolkit");

const couponSlice = createSlice({
  name: "coupon",
  initialState: {
    productsInCoupon: [],
    couponCode: "",
    discount: 0,
    isValid: false,
    loading: false,
  },
  reducers: {
    couponValidity(state, action) {
      state.productsInCoupon = action.payload.productsInCoupon;
      state.couponCode = action.payload.couponCode;
      state.discount = action.payload.discount;
      state.isValid = action.payload.isValid;
      state.loading = false;
    },
    couponValidityRequest(state, action) {
      state.loading = true;
    },

    resetCoupon(state, action) {
      state.productsInCoupon = [];
      state.couponCode = "";
      state.discount = 0;
      state.isValid = false;
    },
  },
});

export const { couponValidity, resetCoupon, couponValidityRequest  } =
  couponSlice.actions;
export default couponSlice.reducer;
