import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";

const CtaOne = ({ spaceTopClass, backgroundImage }) => {
  return (
    <div className={clsx("save-money-area pb-100", spaceTopClass)}>
      <div className="container shadow rounded-3">
        <div
          className="bg-img pt-100 pb-100"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + backgroundImage})`,
          }}
        >
          <div className="save-money-content d-flex justify-content-center flex-column gap-3">
            <h2>Shop and save money</h2>
            <div className="save-money-btn shadow">
              <Link to={process.env.PUBLIC_URL + "/shop"}>Shop Now</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CtaOne.propTypes = {
  backgroundImage: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default CtaOne;
