import axios from "axios";
import cogoToast from "cogo-toast";
import {
  setAddress,
  addNewAddress,
  updateAddress,
  deleteAddressById,
  addressLoading,
  addressLoadingFalse,
} from "../slices/address-slice";

const API_URL = process.env.REACT_APP_API_HOST;

export const addAddress = (data) => {
  return async (dispatch) => {
    try {
      dispatch(addressLoading());
      await axios
        .post(`${API_URL}/address`, data, {
          headers: {
            "x-auth-token": localStorage.getItem("xat"),
          },
        })
        .then((res) => {
          dispatch(addNewAddress(res.data));
          cogoToast.success("Address Added Successfully!", {
            position: "top-center",
          });
        })
        .catch((err) => {
          cogoToast.error("Address not added");
        });
      dispatch(addressLoadingFalse());
    } catch (error) {
      cogoToast.error("Something Went wrong");
      dispatch(addressLoadingFalse());
    }
  };
};

export const editAddress = (id, data) => {
  return async (dispatch) => {
    try {
      await axios
        .put(`${API_URL}/address/${id}`, data, {
          headers: {
            "x-auth-token": localStorage.getItem("xat"),
          },
        })
        .then((res) => {
          dispatch(
            updateAddress({
              id: res.data.id,
              updatedAddress: res.data,
            })
          );
          cogoToast.success("Address Updated Successfully!", {
            position: "top-center",
          });
        })
        .catch((err) => {
          cogoToast.error("Address not updated");
          dispatch(addressLoadingFalse());
        });
    } catch (error) {
      cogoToast.error("Something went wrong");
      dispatch(addressLoadingFalse());
    }
  };
};

export const deleteAddress = (id) => {
  return async (dispatch) => {
    try {
      dispatch(addressLoading());
      await axios
        .delete(`${API_URL}/address/${id}`, {
          headers: {
            "x-auth-token": localStorage.getItem("xat"),
          },
        })
        .then((res) => {
          dispatch(deleteAddressById(res.data.id));
          cogoToast.success("Address Deleted Successfully!", {
            position: "top-center",
          });
        })
        .catch((err) => {
          cogoToast.error("Address Not deleted");
          dispatch(addressLoadingFalse());
        });
    } catch (error) {
      cogoToast.error("Something went wrong");
      dispatch(addressLoadingFalse());
    }
  };
};

export const getAddress = () => {
  return async (dispatch) => {
    try {
      await axios
        .get(`${API_URL}/address`, {
          headers: {
            "x-auth-token": localStorage.getItem("xat"),
          },
        })
        .then((res) => {
          dispatch(setAddress(res.data));
        })
        .catch((err) => {
          cogoToast.error("Something went wrong");
          dispatch(addressLoadingFalse());
        });
    } catch (error) {
      cogoToast.error("Something went wrong");
      dispatch(addressLoadingFalse());
    }
  };
};
