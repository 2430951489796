import React, { Fragment } from "react";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom";
import GoogleMap from "../../components/google-map";

const StoreLocator = () => {
  let { pathname } = useLocation();

  return (
    <Fragment>
      <SEO
        titleTemplate="Store Locator | ASC925"
        description="Store Locator page of  America's Silver Collection."
      />
      <LayoutOne
        headerTop="visible"
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            { label: "Store Locator", path: process.env.PUBLIC_URL + pathname },
          ]}
        />
        <div className="error-area">
          <div className="container-fluid">
            <div className="contact-map mb-10">
              <GoogleMap lat={29.705276} lng={-95.51854} />
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default StoreLocator;
