import axios from "axios";
import {
  createOrderSuccess,
  createOrderFail,
  orderPaymentSuccess,
  createOrderRequest,
  setRedirect,
  resetOrder,
  getMyOrdersRequest,
  getMyOrdersSuccess,
  orderLoading,
} from "../slices/orderReducers";
import cogoToast from "cogo-toast";
const API_URL = process.env.REACT_APP_API_HOST;

export const createOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch(orderLoading());
    dispatch(createOrderRequest());
    const token = localStorage.getItem("xat");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    };
    axios
      .post(`${API_URL}/orders/`, order, config)
      .then((res) => {
        dispatch(createOrderSuccess(res.data));

        setTimeout(() => {
          dispatch(setRedirect());
        }, 5000);
      })
      .catch((err) => {
        cogoToast.error(err.response.data);
        dispatch(createOrderFail(err.response.data));
      });
  } catch (error) {
    cogoToast.error(error.response.data);
    createOrderFail(error.response.data);
  }
};

export const orderPayment = (orderId, secret) => async (dispatch) => {
  try {
    const token = localStorage.getItem("xat");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
        "x-client-secret": secret,
      },
    };
    axios
      .put(`${API_URL}/orders/${orderId}`, {}, config)
      .then((res) => {
        dispatch(orderPaymentSuccess(res.data));
      })
      .catch((err) => {
        cogoToast.error(err.response.data);
      });
  } catch (error) {
    dispatch({
      type: "ORDER_PAYMENT_FAIL",
      payload: error,
    });

    cogoToast.error(error.response.data.message);
  }
};

export const myOrders = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("xat");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    };

    dispatch(getMyOrdersRequest());

    await axios.get(`${API_URL}/orders/customer-order`, config).then((res) => {
      dispatch(getMyOrdersSuccess(res.data));
    });
  } catch (error) {
    console.log(error);
  }
};

export const createOrderGuest = (order) => async (dispatch) => {
  try {
    dispatch(orderLoading());
    dispatch(createOrderRequest());
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(`${API_URL}/orders/guest`, order, config)
      .then((res) => {
        dispatch(createOrderSuccess(res.data));
        setTimeout(() => {
          dispatch(setRedirect());
        }, 3000);
      })
      .catch((err) => {
        cogoToast.error(err.response.data);
        dispatch(createOrderFail(err.response.data));
      });
  } catch (error) {
    cogoToast.error(error.response.data);
    createOrderFail(error.response.data);
  }
};

export const resetTheOrder = () => async (dispatch) => {
  dispatch(resetOrder());
};
