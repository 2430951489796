import PropTypes from "prop-types";


const HeaderTop = ({ borderStyle }) => {
  return (
    // <div className={clsx("header-top-wap", borderStyle === "fluid-border" && "border-bottom")}>
    //   <LanguageCurrencyChanger currency={currency} />
    //   <div className="header-offer">
    //     <p>
    //       Fax 713-773-4112
    //       {/* Free delivery on order over{" "}
    //       <span>
    //         {currency.currencySymbol + (200 * currency.currencyRate).toFixed(2)}
    //       </span> */}
    //     </p>
    //   </div>
    // </div>
    <></>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
};

export default HeaderTop;
