import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import MediaQuery from "../../hooks/media-query";

const HeroSliderSeventeenSingle = ({ data }) => {
  const { isSmallScreen } = MediaQuery("786px");

  const image = data && data.photoSmall[0] ? data.photoSmall[0] : data.photo[0];
  return (
    <div
      as="div"
      className="single-slider-2 slider-height-2 d-flex align-items-center bg-img"
      style={{
        backgroundImage: `url(${isSmallScreen ? image : data?.photo[0]})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-7 col-md-8 col-12">
            <div className="slider-content-2 slider-animated-1">
              <h3 className="animated no-style">{data.title}</h3>
              <h1
                className="animated"
                dangerouslySetInnerHTML={{ __html: data.subtitle }}
              />
              <div className="slider-btn slider-btn--style2 btn-hover">
                <Link
                  className="animated rounden-btn"
                  to={process.env.PUBLIC_URL + data.url}
                >
                  {data.buttonText}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HeroSliderSeventeenSingle.propTypes = {
  data: PropTypes.shape({}),
};

export default HeroSliderSeventeenSingle;
