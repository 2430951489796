import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";

const GoogleMap = ({ lat, lng, zoom, options }) => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDrAPdWEeTucNr1M8Rot03uskbVfijomDI" }}
        defaultCenter={{ lat, lng }}
        defaultZoom={zoom}
      >
        <Marker lat={lat} lng={lng} text="My Marker" />
      </GoogleMapReact>
    </div>
  );
};

GoogleMap.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  zoom: PropTypes.number,
  options: PropTypes.shape({}),
};

GoogleMap.defaultProps = {
  lat: 29.705276,
  lng: -95.51854,
  zoom: 16,
};

export default GoogleMap;

const Marker = ({ text }) => (
  <>
    <div className="map-marker">
      <div
        className="marker-tooltip"
        style={{
          position: "absolute",
          width: "300px",
          textAlign: "center",
          transform: "translate(-45%, -117%)",
          background: "#fff",
          padding: "10px",
        }}
      >
        <h4 style={{ color: "#4c86c6" }}>Shopy Blue</h4>
        <p style={{ color: "#000" }}>
          7500 Bellaire Blvd, Suite 430, Houston, Texas 77036
        </p>
      </div>
      <img
        src={`${process.env.PUBLIC_URL + "/assets/img/icon-img/2.png"}`}
        alt={text}
      />
    </div>
  </>
);
