const putOutOfStockItemsAtLast = (products) => {
  const outOfStockItems = products?.filter((product) => product.stock === "0");
  const inStockItems = products?.filter((product) => product.stock !== "0");
  if (inStockItems === undefined || outOfStockItems === undefined) {
    return products;
  } else {
    const data = [...inStockItems, ...outOfStockItems];
    return data;
  }
};

// get products
export const getProducts = (products, limit, type, category, search) => {
  let pro;

  if (category) {
    pro = products?.filter((product) => product?.category.slug === category);
  }

  if (search) {
    pro = products?.filter((product) =>
      product?.name.toLowerCase().includes(search.toLowerCase())
    );
  }

  if (type === "new") {
    pro = products?.slice(0, limit);
  }

  if (type === "bestSeller") {
    pro = products?.filter((product) => product?.bestSeller === "Yes");
  }

  if (type === "saleItems") {
    const discountedProducts = products?.filter((product) => product?.discount);
    pro = discountedProducts?.filter((product) => product?.discount !== "0");
  }

  const limitedProducts = limit ? pro?.slice(0, limit) : pro;

  const product = putOutOfStockItemsAtLast(limitedProducts);
  return product;
};

export const getProductsForSlider = (products, limit, type, category, relatedProducts) => {
  let pro;
  if (relatedProducts && relatedProducts.length > 0) {

    pro = products?.filter((product) => relatedProducts.includes(product?._id));

  } else if (category) {

    pro = products?.filter((product) => product?.category?.name === category?.name);

  }

  const limitedProducts = limit ? pro?.slice(0, limit) : pro;

  const product = putOutOfStockItemsAtLast(limitedProducts);
  return getIndividualItemArray(product);
};

// get product discount price
export const getDiscountPrice = (price, discount) => {
  return discount && discount !== "0"
    ? (price - price * (discount / 100)).toFixed(2)
    : null;
};

export const taxAmount = (price) => {
  const taxRate = 0.0825;
  const taxAmount = +price * taxRate;
  const totalPriceWithTax = price + taxAmount;
  const totalTax = Number((totalPriceWithTax - price).toFixed(2));

  return totalTax;
};

// get product cart quantity
export const getProductCartQuantity = (cartItems, product, size) => {
  let productInCart = cartItems?.find(
    (single) =>
      single?.id === product?.id &&
      (single?.ringSize ? single?.ringSize === size?.ringSize : true)
  );

  if (cartItems.length >= 1 && productInCart) {
    if (
      product?.ringsSizeAndQuantity &&
      product?.ringsSizeAndQuantity?.length !== 0 &&
      product?.ringsSizeAndQuantity[0].ringSize !== ""
    ) {
      const data = cartItems.find(
        (single) =>
          single?.id === product?.id && single?.ringSize === +size?.ringSize
      )?.quantity;
      return data;
    } else {
      return +cartItems.find((single) => +product?.id === +single?.id).quantity;
    }
  } else {
    return 0;
  }
};

export const cartItemStock = (item, size) => {
  if (item.stock && item.ringSize === "") {
    return item.stock;
  } else {
    const data = size?.find((single) => single?.ringSize === item.ringSize);
    return data?.quantity;
  }
};

export const price = (prices) => {
  const editedPrices = prices.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return editedPrices;
};

export const getProductBySlug = (products, slug) => {
  const product = products?.find((single) => single?.slug === slug);
  return product;
};

export const getSortedProducts = (products, sortType, sortValue, category, subcategory) => {
  let productsData = products;
  if (products && sortType && sortValue) {
    if (sortType === "category") {
      productsData = products?.filter(
        (product) => product?.category.slug === sortValue
      );
    }

    if (sortType === "subcategory" && category) {
      //  eslint-disable-next-line
      productsData = products?.filter((product) => {
        if (product.category && product.subcategory) {
          return (
            product?.category.slug === category &&
            product?.subcategory.slug === sortValue
          );
        }
      });
    }

    if (sortType === "filterSort") {
      let sortProducts = [...products];
      if (sortValue === "default") {
        return sortProducts;
      }
      if (sortValue === "priceHighToLow") {
        return sortProducts?.sort((a, b) => {
          return b.price - a.price;
        });
      }
      if (sortValue === "priceLowToHigh") {
        return sortProducts?.sort((a, b) => {
          return a.price - b.price;
        });
      }
    }

    if (sortType === "metalType") {
      productsData = products?.filter(
        (product) =>
          product?.metalType.toLowerCase() === sortValue.toLowerCase()
      );
    }

    if (sortType === "stones") {
      productsData = products?.filter(
        (product) =>
          product?.stoneType?.toLowerCase() === sortValue?.toLowerCase()
      );
    }

    if (sortType === "stones" && category) {
      productsData = products?.filter(
        (product) =>
          product?.stoneType?.toLowerCase() === sortValue?.toLowerCase() &&
          product.category.slug === category
      );
    }

    if (sortType === "stones" && category && subcategory) {
      productsData = products?.filter(
        (product) =>
          product?.stoneType?.toLowerCase() === sortValue?.toLowerCase() &&
          product.category.slug === category &&
          product.subcategory.slug === subcategory
      );
    }

    if (sortType === "type" && sortValue) {
      if (sortValue === "best-seller") {
        productsData = products?.filter(
          (product) => product?.bestSeller === "Yes"
        );
      }
    }

    if (sortType === "AllMetalTypes" && sortValue) {
      productsData = products?.filter(
        (product) => product?.metalType && product?.metalType !== ""
      );
    }

    if (sortType === "priceFilter" && typeof sortValue === "number") {
      productsData = products.filter((item) => {
        return item.price >= sortValue;
      });
    }
  }

  const outOfStockAtLast = putOutOfStockItemsAtLast(productsData);
  return outOfStockAtLast;
};

// get individual element
const getIndividualItemArray = (array) => {
  const uniqueObjects = array?.filter((item, index, self) => {
    const jsonString = JSON.stringify(item);
    return (
      self.findIndex((obj) => JSON.stringify(obj) === jsonString) === index
    );
  });

  return uniqueObjects;
};

// get individual categories
export const getIndividualCategories = (products) => {
  let productCategories = [];
  products &&
    products?.map((product) => {
      return product?.category && productCategories.push(product?.category);
    });
  const individualProductCategories = getIndividualItemArray(productCategories);
  return individualProductCategories;
};

export const getIndividualStones = (products) => {
  let productStones = [];
  products &&
    products?.map((product) => {
      return product?.stoneType && productStones.push(product?.stoneType);
    });
  const individualProductStones = getIndividualItemArray(productStones);
  return individualProductStones;
};

// get individual tags
export const getIndividualTags = (products) => {
  let productTags = [];
  products &&
    products?.map((product) => {
      return (
        product?.tag &&
        product?.tag.map((single) => {
          return productTags.push(single);
        })
      );
    });
  const individualProductTags = getIndividualItemArray(productTags);
  return individualProductTags;
};

// get individual colors
export const getIndividualColors = (products) => {
  let productColors = [];
  products &&
    products?.map((product) => {
      return (
        product?.variation &&
        product?.variation.map((single) => {
          return productColors.push(single?.color);
        })
      );
    });
  const individualProductColors = getIndividualItemArray(productColors);
  return individualProductColors;
};

// get individual sizes
export const getProductsIndividualSizes = (products) => {
  let productSizes = [];
  products &&
    products?.map((product) => {
      return (
        product?.variation &&
        product?.variation.map((single) => {
          return single?.size?.map((single) => {
            return productSizes.push(single?.name);
          });
        })
      );
    });
  const individualProductSizes = getIndividualItemArray(productSizes);
  return individualProductSizes;
};

// get product individual sizes
export const getIndividualSizes = (product) => {
  let productSizes = [];
  product?.variation &&
    product?.variation.map((singleVariation) => {
      return (
        singleVariation.size &&
        singleVariation.size?.map((singleSize) => {
          return productSizes.push(singleSize?.name);
        })
      );
    });
  const individualSizes = getIndividualItemArray(productSizes);
  return individualSizes;
};

export const setActiveSort = (buttonElement) => {
  if (buttonElement) {
    const filterButtons = document.querySelectorAll(
      ".sidebar-widget-list-left button span"
    );
    filterButtons.forEach((item) => {
      item.parentNode.classList.remove("active");
    });
    buttonElement.parentNode.classList.add("active");
  } else {
    const filterButtons = document.querySelectorAll(
      ".sidebar-widget-list-left button span"
    );
    filterButtons.forEach((item) => {
      item.parentNode.classList.remove("active");
    });
  }
};

export const setActiveSortForStones = (buttonElement) => {
  if (buttonElement) {
    const filterButtons = document.querySelectorAll(
      ".sidebar-widget-list-left-stones button"
    );
    filterButtons.forEach((item) => {
      item.classList.remove("active");
    });
    buttonElement.classList.add("active");
  } else {
    const filterButtons = document.querySelectorAll(
      ".sidebar-widget-list-left-stones button"
    );
    filterButtons.forEach((item) => {
      item.classList.remove("active");
    });
  }
};

export const setActiveLayout = (e) => {
  const gridSwitchBtn = document.querySelectorAll(".shop-tab button");
  gridSwitchBtn.forEach((item) => {
    item.classList.remove("active");
  });
  e.currentTarget.classList.add("active");
};

export const toggleShopTopFilter = (e) => {
  const shopTopFilterWrapper = document.querySelector(
    "#product-filter-wrapper"
  );
  shopTopFilterWrapper.classList.toggle("active");
  if (shopTopFilterWrapper.style.height) {
    shopTopFilterWrapper.style.height = null;
  } else {
    shopTopFilterWrapper.style.height =
      shopTopFilterWrapper.scrollHeight + "px";
  }
  e.currentTarget.classList.toggle("active");
};

export const getMinMaxPrice = (products) => {
  let min = 0,
    max = 0;

  // eslint-disable-next-line array-callback-return
  products?.map((product) => {
    let vMin = product.price;
    let vMax = product.price;
    if (vMin <= min) {
      min = vMin;
    }
    if (vMax >= max) {
      max = vMax;
    }
  });

  return { min, max };
};
