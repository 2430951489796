import axios from "axios";
import { getSubCategories } from "../slices/subcategory-slice";
const API_URL = process.env.REACT_APP_API_HOST;

export const fetchSubCategories = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_URL}/subcategory`);
      dispatch(getSubCategories(response.data));
    } catch (error) {
      console.log(error);
    }
  };
};
