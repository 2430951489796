// import { useSelector } from "react-redux";

const StoreCart = () => {
  // const { cartItems } = useSelector((state) => state.cart);
  // const { isAuthenticated } = useSelector((state) => state.auth);

  
  
  
  
  
  
  return null;
};

export default StoreCart;
