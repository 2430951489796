import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { EffectFade, Thumbs } from "swiper";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Rating from "./sub-components/ProductRating";
import Swiper, { SwiperSlide } from "../../components/swiper";
import { getProductCartQuantity } from "../../helpers/product";
import { addToCart } from "../../store/slices/cart-slice";
import { addToWishlist } from "../../store/slices/wishlist-slice";
import { addToCompare } from "../../store/slices/compare-slice";
import { shortDesc } from "../../helpers/other";
import { useEffect } from "react";
import cogoToast from "cogo-toast";
import { Elements, PaymentMethodMessagingElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  "pk_live_51NwtqXLLQOKbXusIKpRac713FY5aDMefyiQh3HmlezL7Z2tGsajzzI7MhF5KCbozF5e2hEQugj5qqdP7KRqryxip003Ej1sDvp"
);

function ProductModal({
  product,
  discountedPrice,
  finalProductPrice,
  finalDiscountedPrice,
  show,
  onHide,
  wishlistItem,
  compareItem,
}) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);

  const [productStock, setProductStock] = useState(
    +product?.stock
  );

  const [ringSizeCal, setRingSizeCal] = useState({});

  const [quantityCount, setQuantityCount] = useState(1);

  const sizes = [
    {
      ringSize: "5",
      quantity: productStock,
      _id: "0",
    },
    {
      ringSize: "5.5",
      quantity: productStock,
      _id: "1",
    },
    {
      ringSize: "6",
      quantity: productStock,
      _id: "2",
    },
    {
      ringSize: "6.5",
      quantity: productStock,
      _id: "3",
    },
    {
      ringSize: "7",
      quantity: productStock,
      _id: "4",
    },
    {
      ringSize: "7.5",
      quantity: productStock,
      _id: "5",
    },
    {
      ringSize: "8",
      quantity: productStock,
      _id: "6",
    },
    {
      ringSize: "8.5",
      quantity: productStock,
      _id: "7",
    },
    {
      ringSize: "9",
      quantity: productStock,
      _id: "8",
    },
    {
      ringSize: "9.5",
      quantity: productStock,
      _id: "9",
    },
    {
      ringSize: "10",
      quantity: productStock,
      _id: "10",
    },
    {
      ringSize: "10.5",
      quantity: productStock,
      _id: "11",
    }
    ,
    {
      ringSize: "11",
      quantity: productStock,
      _id: "12",
    },
    {
      ringSize: "11.5",
      quantity: productStock,
      _id: "13",
    },
    {
      ringSize: "12",
      quantity: productStock,
      _id: "14",
    },
    {
      ringSize: "12.5",
      quantity: productStock,
      _id: "15",
    },
    {
      ringSize: "13",
      quantity: productStock,
      _id: "16",
    }

  ]

  const [selectedRingSize, setSelectedRingSize] = useState(product?.ringsSizeAndQuantity.length !== 0 ? sizes[0]?.ringSize : '');

  useEffect(() => {
    setRingSizeCal(
      // product?.ringsSizeAndQuantity &&
      //   product?.ringsSizeAndQuantity.length !== 0 &&
      //   product?.ringsSizeAndQuantity[0]?.ringSize !== "" &&
      //   product?.ringsSizeAndQuantity[0]?.ringSize
      //   ? product?.ringsSizeAndQuantity[0]
      //   : {}
      sizes[0]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const productCartQty = getProductCartQuantity(
    cartItems,
    product,
    ringSizeCal
  );

  const gallerySwiperParams = {
    spaceBetween: 10,
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    thumbs: { swiper: thumbsSwiper },
    modules: [EffectFade, Thumbs],
  };

  const thumbnailSwiperParams = {
    onSwiper: setThumbsSwiper,
    spaceBetween: 10,
    slidesPerView: 4,
    touchRatio: 0.2,
    freeMode: true,
    loop: true,
    slideToClickedSlide: true,
    autoPlay: true
  };

  const handleSizeChange = (size) => {
    const { quantity } = size;
    setSelectedRingSize(size.ringSize);
    setProductStock(quantity);
    setRingSizeCal(size);
  };

  const onCloseModal = () => {
    setThumbsSwiper(null);
    onHide();
  };

  const images =
    product &&
    product.images &&
    product?.images.filter((item) => {
      const fileExtension = item.substring(item.lastIndexOf(".")).toLowerCase();
      const supportedExtensions = [".jpg", ".jpeg", ".png", ".gif", ".webp"];
      return supportedExtensions.includes(fileExtension);
    });
  const videos =
    product &&
    product.images &&
    product?.images.filter((item) => item.endsWith(".mp4"));

  const cartQty = productCartQty ? productCartQty : 0;

  const discountPrice = +(finalDiscountedPrice * 100).toFixed(2);
  const productPrice = +(finalProductPrice * 100).toFixed(2);

  return (
    <Modal
      show={show}
      w
      onHide={onCloseModal}
      className="product-quickview-modal-wrapper"
    >
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body">
        <div className="row">
          <div className="col-md-5 col-sm-12 col-xs-12">
            <div className="product-large-image-wrapper">
              {images && images.length > 0 && (
                <Swiper options={gallerySwiperParams}>
                  {images.map((single, key) => (
                    <SwiperSlide key={key}>
                      <button
                        className="lightgallery-button"
                      // onClick={() => setIndex(key)}
                      >
                        <i className="pe-7s-expand1"></i>
                      </button>
                      <div className="single-image d-flex">
                        <img
                          src={process.env.PUBLIC_URL + single}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                  {videos && videos.length > 0 && (
                    <SwiperSlide>
                      <div className="single-image d-flex">
                        <video
                          src={process.env.PUBLIC_URL + videos[0]}
                          loop
                          autoPlay
                          className="img-fluid"
                        />
                      </div>
                    </SwiperSlide>
                  )}
                </Swiper>
              )}
            </div>
            <div className="product-small-image-wrapper mt-15">
              {images && images.length > 0 && (
                <Swiper options={thumbnailSwiperParams}>
                  {images.map((single, key) => (
                    <SwiperSlide key={key}>
                      <div className="single-image d-flex">
                        <img
                          src={process.env.PUBLIC_URL + single}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                  {videos && videos.length > 0 && (
                    <SwiperSlide>
                      <div className="single-image d-flex">
                        <video
                          src={process.env.PUBLIC_URL + videos[0]}
                          autoPlay
                          className="img-fluid"
                        />
                      </div>
                    </SwiperSlide>
                  )}
                </Swiper>
              )}
            </div>
          </div>
          <div className="col-md-7 col-sm-12 col-xs-12">
            <div className="product-details-content quickview-content">
              <h2>{product?.name}</h2>
              <p>Reference: {product?.reference}</p>

              <div>
                {discountedPrice !== null ? (
                  <Fragment>
                    <div className="product-details-price">
                      <span>{"$" + finalDiscountedPrice}</span>{" "}
                      <span className="old">{"$" + finalProductPrice}</span>
                    </div>
                    <div>
                      <Elements stripe={stripePromise}>
                        <PaymentMethodMessagingElement options={{
                          amount: discountPrice,
                          currency: 'USD',
                          paymentMethodTypes: ['klarna', 'affirm'],
                          countryCode: 'US'
                        }}
                        />
                      </Elements>
                    </div>
                  </Fragment>
                ) : (
                  <>
                    <div className="product-details-price">
                      <span>{"$" + finalProductPrice} </span>
                    </div>
                    <div>
                      <Elements stripe={stripePromise}>
                        <PaymentMethodMessagingElement options={{
                          amount: productPrice,
                          currency: 'USD',
                          paymentMethodTypes: ['klarna', 'affirm'],
                          countryCode: 'US'
                        }}
                        />
                      </Elements>
                    </div>
                  </>
                )}
              </div>

              {product?.rating && product?.rating > 0 ? (
                <div className="pro-details-rating-wrap">
                  <div className="pro-details-rating">
                    <Rating ratingValue={product?.rating} />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="pro-details-list">
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${shortDesc(product?.description)}`,
                  }}
                />
              </div>

              {
                (product?.category?.slug === "rings" || product?.category?.slug === "bands")
                && (
                  <div className="pro-details-size-color">
                    <div className="pro-details-size">
                      <p className="mx-1">Size</p>
                      <div className="pro-details-size-content">
                        {sizes.map((size, index) => {
                          return (
                            <label
                              key={index}
                              className={`pro-details-size-content--single ${selectedRingSize === size?.ringSize ? "active" : ""
                                }`}
                            >
                              <input
                                type="radio"
                                value={size?.ringSize}
                                checked={selectedRingSize === size?.ringSize}
                                onChange={() => handleSizeChange(size)}
                              />
                              <span className="size-name">{size?.ringSize}</span>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )
              }
              {product?.affiliateLink ? (
                <div className="pro-details-quality">
                  <div className="pro-details-cart btn-hover">
                    <a
                      href={product?.affiliateLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              ) : (
                <div className="pro-details-quality">
                  <div className="cart-plus-minus">
                    <button
                      onClick={() =>
                        setQuantityCount(
                          quantityCount > 1 ? quantityCount - 1 : 1
                        )
                      }
                      className="dec qtybutton"
                    >
                      -
                    </button>
                    <input
                      className="cart-plus-minus-box"
                      type="text"
                      value={quantityCount}
                      readOnly
                    />
                    <button
                      onClick={() => {
                        setQuantityCount((prev) => {
                          if (prev < productStock - cartQty) {
                            return prev + 1;
                          } else {
                            cogoToast.error("Product Stock Limit Exceeded");
                            return prev;
                          }
                        });
                      }}
                      className="inc qtybutton"
                    >
                      +
                    </button>
                  </div>
                  <div className="pro-details-cart btn-hover">
                    {productStock && productStock > 0 ? (
                      <button
                        onClick={() => {
                          dispatch(
                            addToCart({
                              ...product,
                              quantity: quantityCount,
                              ringSize: selectedRingSize,
                            })
                          );
                          setQuantityCount(1);
                        }}
                        disabled={productStock === cartQty ? true : false}
                      >
                        {" "}
                        Add To Cart
                      </button>
                    ) : (
                      <button disabled>Out of Stock</button>
                    )}
                  </div>
                  <div className="pro-details-wishlist">
                    <button
                      className={wishlistItem !== undefined ? "active" : ""}
                      disabled={wishlistItem !== undefined}
                      title={
                        wishlistItem !== undefined
                          ? "Added to wishlist"
                          : "Add to wishlist"
                      }
                      onClick={() => dispatch(addToWishlist(product))}
                    >
                      <i className="pe-7s-like" />
                    </button>
                  </div>
                  <div className="pro-details-compare">
                    <button
                      className={compareItem !== undefined ? "active" : ""}
                      disabled={compareItem !== undefined}
                      title={
                        compareItem !== undefined
                          ? "Added to compare"
                          : "Add to compare"
                      }
                      onClick={() => dispatch(addToCompare(product))}
                    >
                      <i className="pe-7s-shuffle" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ProductModal.propTypes = {
  currency: PropTypes.shape({}),
  discountedprice: PropTypes.number,
  finaldiscountedprice: PropTypes.number,
  finalproductprice: PropTypes.number,
  onHide: PropTypes.func,
  product: PropTypes.shape({}),
  show: PropTypes.bool,
  wishlistItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({}),
};

export default ProductModal;
